<div class="services-offered-body">
  <h2 mdcHeadline3 class="headline3">Services offerts</h2>
  <p mdcBody1>
    Notre équipe est spécialisé en traitements spécifiques selon les pathologies suivantes :
    lombalgie, dorsalgie, sciatalgie, cervicalgie, problématiques musculo-squelettiques, récupération
    après chirurgie et posture. Nous offrons également les soins suivants.
  </p>

  <mdc-card>
    <div *ngFor="let service of services">
      <mdc-list-divider *ngIf="service != services[0]"></mdc-list-divider>
      <mdc-card-primary-action (click)="collapse($event.currentTarget)">
        <div class="card-media-list">
          <div class="card-media mdc-card__media mdc-card__media--square" [style.background-image]="service.photo">
          </div>
          <div class="card-content">
            <div mdcBody1>{{service.title}}</div>
          </div>
        </div>
      </mdc-card-primary-action>
      <div class="card-media-list content">
        <div class="card-content-description shadow" mdcBody2>
          <p>{{service.desc}}</p>
          <div *ngIf="service.source" >
            <br>
            <a [href]="service.source">
              Source
            </a>
          </div>
        </div>
      </div>
    </div>
  </mdc-card>
</div>