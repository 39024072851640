<mdc-top-app-bar class="topbar" [ngClass]="handleShadowClass(topbarList)">
  <mdc-top-app-bar-row class="topbar-title">
    <mdc-top-app-bar-section align="start">
      <button mdcTopAppBarNavIcon (click)="handleNavButton(topbarList)">
        <mdc-icon *ngIf="topbarList.style.display === 'none'">menu</mdc-icon>
        <mdc-icon *ngIf="topbarList.style.display === 'block'">close</mdc-icon>
      </button>
      <div [routerLink]="['/accueil']">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 800 66.67"><path [attr.d]="logo"/></svg>
      </div>
    </mdc-top-app-bar-section>
    <mdc-top-app-bar-section align="end">
      <button mdcTopAppBarActionItem>
        <a href="tel:514-513-2856">
          <mdc-icon>phone</mdc-icon>
        </a>
      </button>
    </mdc-top-app-bar-section>
  </mdc-top-app-bar-row>
  <div class="topbar-list" #topbarList style="display: none;">
    <mdc-list class="list--shaped">
      <a mdc-list-item *ngFor="let item of destinations" (click)="handleDestinationActivated($event.currentTarget, topbarList)" [routerLink]="item.link" [activated]="item.activated">
        <mdc-icon mdcListItemGraphic *ngIf="item.icon">{{item.icon}}</mdc-icon>{{item.label}}
      </a>
    </mdc-list>
  </div>
</mdc-top-app-bar>
<div class="drawer-view-container" >
  <mdc-drawer class="drawer">
    <mdc-drawer-header>
      <div class="svg-logo">
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="67" viewBox="0 0 400 66.67"><path [attr.d]="logo"/></svg>
      </div>
      <div mdcSubtitle1 class="phone">
        <a href="tel:514-513-2856">514-513-2856</a>
      </div>
    </mdc-drawer-header>
    <mdc-drawer-content>
      <mdc-list class="list--shaped">
        <a mdc-list-item *ngFor="let item of destinations" [routerLink]="item.link" [activated]="item.activated">
          <mdc-icon mdcListItemGraphic *ngIf="item.icon">{{item.icon}}</mdc-icon>{{item.label}}
        </a>
      </mdc-list>
    </mdc-drawer-content>
  </mdc-drawer>
  <router-outlet></router-outlet>
</div>
