<div class="tarifs-body">
  <h2 mdcHeadline3 class="headline3">Tarifs</h2>
  <mdc-card>
    <mdc-data-table class="table thText">
      <table mdcDataTableTable>
        <thead>
          <tr mdcDataTableHeaderRow>
            <th mdcDataTableHeaderCell style="white-space: normal;">Kinésithérapie • Orthothérapie • Massothérapie</th>
          </tr>
        </thead>
        <tbody mdcDataTableContent>
          <tr mdcDataTableRow *ngFor="let item of pricesMasso">
            <td mdcDataTableCell>{{item.service}}</td>
            <td mdcDataTableCell class="table-price-col">{{item.price | currency}}</td>
          </tr>
        </tbody>
      </table>
      <table mdcDataTableTable>
        <thead>
          <tr mdcDataTableHeaderRow>
            <th mdcDataTableHeaderCell>Autres services</th>
          </tr>
        </thead>
        <tbody mdcDataTableContent>
          <tr mdcDataTableRow *ngFor="let item of pricesOthers">
            <td mdcDataTableCell>{{item.service}}</td>
            <td mdcDataTableCell class="table-price-col">{{item.price | currency}}</td>
          </tr>
        </tbody>
      </table>
    </mdc-data-table>
  </mdc-card>

  <p class="call-message">Appelez-nous pour savoir ce qui vous conviendrait le mieux!</p>
</div>