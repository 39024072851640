<div class="hero-image">
  <div class="hero-text">
    <div mdcHeadline2 class="hero-text-title">Soins thérapeutiques et massages</div>
    <br />
    <div mdcSubtitle1>Kinésithérapie •&nbsp;Orthothérapie •&nbsp;Massothérapie</div>
  </div>
</div>
<div class="accueil-body">
  <h2 mdcHeadline3 class="headline3">Bienvenue chez KynesioLux</h2>
  <div mdcBody1>
    À la clinique KynesioLux, notre but est simple : vous aider à optimiser
    votre santé! Pour vos douleurs musculaires et articulaires, notre clinique
    est votre solution. Notre équipe a le souci de vous offrir ce qu'il y a de
    mieux en services d'aide et de traitements, tout en répondant à vos attentes
    particulières et à vos besoins spécifiques. Nos services s'adressent à toute
    la famille (femmes, hommes, enfants) ainsi qu'aux sportifs. Profitez aussi
    de notre table spécialement conçue pour les femmes enceintes. Nos
    professionnels utilisent plusieurs techniques telles que le massage profond,
    les trigger points, les mobilisations isométriques, le taping, ainsi que les
    étirements myofasciaux afin de procurer la meilleure libération des tensions
    et des douleurs diverses.
  </div>

  <h3 mdcHeadline3 class="headline3">Notre équipe</h3>

  <mdc-card>
    <div *ngFor="let member of team">
      <mdc-list-divider *ngIf="member != team[0]"></mdc-list-divider>
      <div class="card-media-list">
        <div
          class="card-media mdc-card__media mdc-card__media--square"
          [style.background-image]="member.photo"
        ></div>
        <div class="card-content">
          <div mdcHeadline4 class="headline4">{{ member.name }}</div>
          <div mdcSubtitle1>{{ member.title }}</div>
        </div>
      </div>
    </div>
  </mdc-card>
</div>
